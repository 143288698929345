<template>
  <div class="app px-5 max-w-2xl mx-auto my-20">
    <h1 class="text-center text-6xl font-bold text-secondary mb-10">קוד QR</h1>
    <p class="text-center">
      הוא הכלי המושלם לחיבור מהיר בין לקוחות למידע, מוצרים ושירותים.
      <br>
      אצלי באתר תוכלו ליצור QR קוד בהתאמה אישית ובקלות ובכך להעצים את הנוכחות הדיגיטלית שלכם בצורה חכמה ויעילה.
    </p>
    <h2 class="text-center text-4xl font-bold text-tertiary my-8">יצירת QR קוד משלך</h2>
    <div class="my-5">
      <buttons-group
          v-model="type"
          :options="selectOptions"
      ></buttons-group>
    </div>
    <div class="fields" v-if="type === 'link'">
      <text-field
        v-model="link"
        label="הזנ\י את הקישור שלך כאן"
        id="link"
      ></text-field>
    </div>
    <div class="fields" v-else-if="type === 'text'">
      <text-field
        type="textarea"
        v-model="text"
        label="הזנ\י את הטקסט שלך כאן"
      ></text-field>
    </div>
    <div class="fields" v-else-if="type === 'email'">
      <text-field
        v-model="email"
        type="email"
        label="הזנ\י את האימייל שלך כאן"
      ></text-field>
    </div>
    <div class="fields" v-else-if="type === 'phone'">
      <text-field
        v-model="phone"
        type="tel"
        label="הזנ\י את מספר הטלפון שלך כאן"
      ></text-field>
    </div>
    <div class="fields" v-else-if="type === 'sms'">
      <text-field
        v-model="smsNumber"
        type="tel"
        label="הזנ\י את מספר הטלפון שלך כאן"
      ></text-field>
      <text-field
        v-model="smsMessage"
        type="text"
        label="הזנ\י את ההודעה שלך כאן"
      ></text-field>
    </div>
    <div class="fields" v-else-if="type === 'wifi'">
      <text-field
        v-model="wifiSsid"
        type="text"
        label="הזנ\י את ה-SSID שלך כאן"
      ></text-field>
      <text-field
        v-model="wifiPassword"
        type="text"
        label="הזנ\י את הסיסמה שלך כאן"
      ></text-field>
    </div>
    <div class="fields" v-else-if="type === 'contact'">
      <text-field
        v-model="contact.name"
        type="text"
        label="הזנ\י את שמך כאן"
      ></text-field>
      <text-field
        v-model="contact.email"
        type="email"
        label="הזנ\י את האימייל שלך כאן"
      ></text-field>
      <text-field
        v-model="contact.phone"
        type="tel"
        label="הזנ\י את מספר הטלפון שלך כאן"
      ></text-field>
      <text-field
        v-model="contact.website"
        type="url"
        label="הזנ\י את האתר שלך כאן"
      ></text-field>
    </div>
    <div class="text-center">
      <button
          class="bg-amber-600 rounded-2xl px-10 py-2 text-blue-950 font-bold"
          @click="generateQRCode"
      >יצירת קוד</button>
      <div v-if="dataUri" class="results">
        <img :src="dataUri" alt="Qr Code">
        <a class="bg-amber-600 rounded-2xl px-10 py-2 text-blue-950 font-bold" :href="dataUri" download="qr-code.png">הורדת הקוד</a>
      </div>
    </div>
    <div class="text-center">
      <p class="my-4">ה-QR קוד (Quick Response Code) הומצא בשנת 1994 על ידי חברה יפנית בשם Denso Wave, חברת בת של תאגיד טויוטה. QR קוד פותח במקור לצורך מעקב אחר חלקי רכב במהלך תהליך הייצור. המטרה המרכזית הייתה ליצור קוד שיכול להכיל יותר מידע מאשר ברקוד רגיל ויהיה קל לסריקה במהירות.</p>
      <p class="mb-4">הקוד התפשט במהירות לשימושים שונים מעבר לתעשיית הרכב, ובשנות ה-2000 המוקדמות החל להופיע בכל העולם. השימוש ב-QR קודים גדל בעיקר בזכות התפשטות הסמרטפונים, שהפכו את הסריקה לקלה ונגישה לכל אחד.</p>
      <p class="mb-4">איך עובד QR קוד?</p>
      <p class="mb-4">QR קוד הוא סוג של ברקוד דו-ממדי המורכב מריבועים שחורים על רקע לבן. כל קוד מכיל מידע בצורה של תבנית של ריבועים שחורים ולבנים. כאשר סורקים את הקוד בעזרת מצלמה של סמארטפון או כל מכשיר מתאים אחר, התוכנה מזהה את התבנית ומתרגמת אותה למידע קריא כמו טקסט, קישור לאתר אינטרנט, מספר טלפון, או כל סוג אחר של מידע.</p>
      <p class="mb-4">QR קוד יכול להכיל הרבה יותר מידע מאשר ברקוד חד-ממדי, והוא עמיד יותר מפני נזק. הקוד יכול להכיל עד 7,089 תווים של מידע, והוא יכול להיקרא גם אם חלק ממנו ניזוק או חסר, בזכות מנגנוני תיקון שגיאות מובנים.</p>
      <p class="mb-4">שימושים של QR קודים</p>
      <p class="mb-4">השימושים ב-QR קודים הם רבים ומגוונים, והם נראים כמעט בכל תחום בחיים המודרניים:</p>
      <p class="mb-4">תיירות ומוזיאונים: מבקרים יכולים לסרוק QR קודים לקבלת מידע נוסף אודות אתר תיירות או מוצג במוזיאון, דבר המעשיר את חוויית הביקור.</p>
      <p class="mb-4">שיווק ופרסום: QR קודים משמשים להפניית לקוחות לאתרי אינטרנט, עמודי נחיתה, או אפליקציות להורדה. ניתן לראות אותם על גבי פרסומות בעיתונים, שלטי חוצות, ואריזות מוצרים.</p>
      <p class="mb-4">כרטיסים לאירועים ותשלומים: רבים משתמשים ב-QR קודים לתשלומים בבתי עסק או לרכישת כרטיסים לאירועים. הקודים מאפשרים תהליך תשלום מהיר ומאובטח.</p>
      <p class="mb-4">שירות לקוחות: QR קודים יכולים להפנות לקוחות ישירות לדפי תמיכה או למידע נוסף אודות מוצר מסוים, דבר שמפחית את הצורך בשירות לקוחות טלפוני.</p>
      <p class="mb-4">ניהול מלאי ולוגיסטיקה: בעסקים ובמפעלים, QR קודים משמשים למעקב אחרי מלאי, ניהול משלוחים, ותיעוד תהליכים לוגיסטיים.</p>
      <p class="mb-4">QR קוד הוא כלי רב עוצמה שנמצא בשימוש רחב בזכות קלות השימוש והגמישות שלו. הוא מאפשר גישה מהירה למידע, והפך לכלי חיוני בשיווק, תשלומים, ניהול מלאי, ועוד. עם ההתפתחות הטכנולוגית המתמדת, סביר להניח שנמשיך לראות עוד ועוד יישומים חדשים ומרגשים של QR קודים בעתיד.</p>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import QRCode from 'qrcode';
import TextField from "@/components/TextField.vue";
import ButtonsGroup from "@/components/ButtonsGroup.vue";

const type = ref('link');
const link = ref('');
const text = ref('');
const email = ref('');
const phone = ref('');
const smsNumber = ref('');
const smsMessage = ref('');
const wifiSsid = ref('');
const wifiPassword = ref('');
const contact = ref({
  name: '',
  email: '',
  phone: '',
  website: '',
});
const dataUri = ref(null);

const selectOptions = [
  { value: 'link', label: 'קישור' },
  { value: 'text', label: 'טקסט' },
  { value: 'email', label: 'אימייל' },
  { value: 'phone', label: 'טלפון' },
  { value: 'sms', label: 'הודעה' },
  { value: 'wifi', label: 'WiFi' },
  { value: 'contact', label: 'איש קשר' }
];

const generateQRCode = () => {
  const canvas = document.createElement('canvas');
  switch(type.value){
    case 'link':
      QRCode.toCanvas(canvas, link.value, { width: 500, padding: 10 }, (error) => {
        if (error) console.error(error);
      });
      QRCode.toDataURL(link.value, { width: 500 }, (error, uri) => {
        if (error) console.error(error);
        dataUri.value = uri;
      });
      break;
    case 'text':
      QRCode.toCanvas(canvas, text.value, { width: 500 }, (error) => {
        if (error) console.error(error);
      });
      QRCode.toDataURL(text.value, { width: 500 }, (error, uri) => {
        if (error) console.error(error);
        dataUri.value = uri;
      });
      break;
    case 'email':
      QRCode.toCanvas(canvas, `mailto:${email.value}`, { width: 500 }, (error) => {
        if (error) console.error(error);
      });
      QRCode.toDataURL(`mailto:${email.value}`, { width: 500 }, (error, uri) => {
        if (error) console.error(error);
        dataUri.value = uri;
      });
      break;
    case 'phone':
      QRCode.toCanvas(canvas, `tel:${phone.value}`, { width: 500 }, (error) => {
        if (error) console.error(error);
      });
      QRCode.toDataURL(`tel:${phone.value}`, { width: 500 }, (error, uri) => {
        if (error) console.error(error);
        dataUri.value = uri;
      });
      break;
    case 'sms':
      QRCode.toCanvas(canvas, `smsto:${smsNumber.value}:${smsMessage.value}`, { width: 500 }, (error) => {
        if (error) console.error(error);
      });
      QRCode.toDataURL(`smsto:${smsNumber.value}:${smsMessage.value}`, { width: 500 }, (error, uri) => {
        if (error) console.error(error);
        dataUri.value = uri;
      });
      break;
    case 'wifi':
      QRCode.toCanvas(canvas, `WIFI:S:${wifiSsid.value};T:WPA;P:${wifiPassword.value};;`, { width: 500 }, (error) => {
        if (error) console.error(error);
      });
      QRCode.toDataURL(`WIFI:S:${wifiSsid.value};T:WPA;P:${wifiPassword.value};;`, { width: 500 }, (error, uri) => {
        if (error) console.error(error);
        dataUri.value = uri;
      });
      break;
    case 'contact':
      QRCode.toCanvas(canvas, `BEGIN:VCARD\nVERSION:3.0\nFN:${contact.value.name}\nEMAIL:${contact.value.email}\nTEL:${contact.value.phone}\nURL:${contact.value.website}\nEND:VCARD`, { width: 500 }, (error) => {
        if (error) console.error(error);
      });
      QRCode.toDataURL(`BEGIN:VCARD\nVERSION:3.0\nFN:${contact.value.name}\nEMAIL:${contact.value.email}\nTEL:${contact.value.phone}\nURL:${contact.value.website}\nEND:VCARD`, { width: 500 }, (error, uri) => {
        if (error) console.error(error);
        dataUri.value = uri;
      });
      break;
  }
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input,
textarea,
select {
  padding: 10px;
  font-size: 16px;
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

canvas,
img{
  margin-top: 20px;
  max-width: 500px;
  width: 100%;
}

.fields {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.results {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
</style>