<template>
  <div class="relative bg-inherit my-4">
    <textarea
      v-if="type === 'textarea'"
      :id="id"
      :name="name"
      class="peer bg-transparent h-10 w-60 rounded-lg text-gray-900 placeholder-transparent ring-2 px-2 ring-gray-500 focus:ring-amber-500 focus:outline-none focus:border-rose-600"
      :placeholder="placeholder"
      :value="modelValue"
      @input="emit('update:modelValue', $event.target.value)"
    ></textarea>
    <input
      v-else
      :type="type"
      :id="id"
      :name="name"
      class="peer bg-transparent h-10 w-60 rounded-lg text-gray-900 placeholder-transparent ring-2 px-2 ring-gray-500 focus:ring-amber-500 focus:outline-none focus:border-rose-600"
      :placeholder="placeholder"
      :value="modelValue"
      @input="emit('update:modelValue', $event.target.value)"
    />
    <label
      :for="id"
      class="bg-primary absolute cursor-text right-0 -top-3 text-sm text-gray-500 mx-1 px-1 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-2 peer-focus:-top-3 peer-focus:text-amber-500 peer-focus:text-sm transition-all"
    >
      {{ label }}
    </label>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

defineProps({
  label: {
    type: String,
    required: false
  },
  type: {
    type: String,
    default: 'text'
  },
  name: {
    type: String,
    required: false
  },
  id: {
    type: String,
    required: false,
    default: () => {
      return `text-field-${Math.random().toString(36).substring(7)}`;
    }
  },
  placeholder: {
    type: String,
    default: ''
  },
  modelValue: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['update:modelValue']);
</script>