<template>
  <div class="button-group" :id="id">
    <div class="flex flex-wrap justify-center gap-2">
      <button
        v-for="option in options"
        :key="option.value"
        @click="selectOption(option)"
        :class="[
          'py-2 px-4 rounded-md text-white',
          option.value === modelValue ? 'bg-quaternary' : 'bg-secondary'
        ]"
      >
        {{ option.label }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

defineProps({
  modelValue: {
    type: String,
    required: true
  },
  options: {
    type: Array,
    required: true,
    default: () => []
  },
  id: {
    type: String,
    required: false,
    default: () => {
      return `button-group-${Math.random().toString(36).substring(7)}`;
    }
  }
});

const emit = defineEmits(['update:modelValue']);

const selectOption = (option) => {
  emit('update:modelValue', option.value);
};
</script>